import React, { useEffect, useState } from "react";
import { Header } from "../components/Header";
import { Button, DatePicker, Flex, Form, Input, Modal, Space, Table } from "antd";
import { useAsyncFn } from "react-use";
import { mockPromise } from "../../mockPromise";
import { ColumnsType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import { Device, fetchDevices } from "./api/fetchDevices";
import { DeviceReq, addDevice } from "./api/addDevice";
import { useForm } from "antd/es/form/Form";

const columns: ColumnsType<Device> = [
  {
    title: "Room Number",
    dataIndex: "room",
    key: "room",
  },
  {
    title: "Device Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Creation Date",
    dataIndex: "createdAt",
    key: "createdAt",
  },
  {
    title: "Expiration Date",
    dataIndex: "expiresAt",
    key: "expiresAt",
  },
  {
    title: "Actions",
    render: () => <Button type="link">Remove Device</Button>,
  },
];

export const Devices: React.FC = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [devices, setDevices] = useState<Device[]>([]);

  const [saving, setSaving] = useState<boolean>(false);
  const [form] = useForm<DeviceReq>();

  const onFinish = async (deviceReq: DeviceReq) => {
    setSaving(true);
    await addDevice(deviceReq);
    setSaving(false);
    setOpenModal(false);
    await doFetchDevices();
  };

  const doFetchDevices = async () => {
    setLoading(true);
    const devices = await fetchDevices();
    setDevices(devices);
    setLoading(false);
  };

  useEffect(() => {
    doFetchDevices();
  }, []);

  return (
    <>
      <Flex vertical gap={16}>
        <Header
          title="Guests"
          controls={
            <Flex gap={12}>
              <Space.Compact>
                <Input
                  size="large"
                  placeholder="Search Device"
                  suffix={<SearchOutlined style={{ color: "lightgrey" }} />}
                />
              </Space.Compact>
              <Button size="large" onClick={() => setOpenModal(true)} type="primary">
                Add Guest
              </Button>
            </Flex>
          }
        />
        <Table loading={loading} columns={columns} dataSource={devices} size="middle" />
      </Flex>
      <Modal
        open={openModal}
        onCancel={() => setOpenModal(false)}
        title="Add New Guest"
        onOk={() => form.submit()}
        confirmLoading={saving}
        width={600}
      >
        <Form form={form} style={{ paddingTop: 15 }} labelCol={{ span: 8 }} onFinish={onFinish}>
          <Form.Item<string>
            label="Code"
            name="pin"
            rules={[{ required: true, message: "Please input the guest code!" }]}
            // extra="a53e1fec-14ea-402a-adf3-6c361af99d55"
          >
            <Input />
          </Form.Item>
          <Form.Item<string>
            label="Room #"
            name="room"
            rules={[{ required: true, message: "Please input the guest code!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item<string> label="Device Name" name="name">
            <Input />
          </Form.Item>
          {/* <Form.Item<number> label="Account #" name="accountNumber">
            <Input />
          </Form.Item>
          <Form.Item<string> label="Reservation Period" name="reservationPeriod">
            <DatePicker.RangePicker style={{ width: "100%" }} />
          </Form.Item> */}
        </Form>
      </Modal>
    </>
  );
};
