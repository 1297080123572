import { Endpoint, LocationId } from "../../../constants";

export type Device = {
  createdAt: string;
  expiresAt: string;
  updatedAt: string;

  id: string;
  key: string;
  locationId: string;
  name: string;
  phone: string;
  room: string;
};

export const fetchDevices = async () => {
  const response = await fetch(Endpoint + `/locations/${LocationId}/devices`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });

  return (await response.json()) as Device[];
};
