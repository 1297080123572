import { Endpoint } from "../../../constants";
import { Device } from "./fetchDevices";

export const fetchDevice = async (deviceId: string) => {
  try {
    const response = await fetch(Endpoint + `/devices/${deviceId}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) return (await response.json()) as Device;
    else return undefined;
  } catch (e) {
    return undefined;
  }
};
