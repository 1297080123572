import { Flex } from "antd";

interface Props {
  pin: string;
}
export const FancyPin = ({ pin }: Props) => {
  const chars = pin.split("");

  return (
    <Flex gap={16} align="center" justify="center">
      {chars.map((char, i) => (
        <Flex
          align="center"
          justify="center"
          style={{
            border: "1px solid #555555",
            borderRadius: 6,
            backgroundColor: "#2F203C",
            fontSize: 36,
            color: "white",
            width: 55,
            height: 67,
          }}
        >
          <span key={`char-${i}`}>{char}</span>
        </Flex>
      ))}
    </Flex>
  );
};
