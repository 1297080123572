import { ArrowLeftOutlined } from "@ant-design/icons";
import { Divider, Flex, Typography } from "antd";
import { ReactNode } from "react";

interface Props {
  title: string;
  controls?: ReactNode;
  onBack?: () => void;
}

export const Header = ({ title, controls, onBack }: Props) => {
  const { Title } = Typography;

  return (
    <>
      <Flex align="center" justify="space-between">
        <Flex gap={12}>
          {onBack && <ArrowLeftOutlined style={{ fontSize: 20 }} onClick={() => onBack()} />}
          <Title style={{ margin: 0 }} level={2}>
            {title}
          </Title>
        </Flex>
        {controls}
      </Flex>
      <Divider style={{ padding: 0, margin: 0 }} />
    </>
  );
};
