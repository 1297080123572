import { Endpoint, LocationId } from "../../constants";

export type Identity = {
  eat: number;
  pin: string;
  key: string;
};

export const fetchIdentity = async () => {
  const response = await fetch(Endpoint + "/identities", {
    method: "post",
    body: JSON.stringify({ locationId: LocationId }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return (await response.json()) as Identity;
};
