import { Endpoint, LocationId } from "../../../constants";
import { Device } from "./fetchDevices";

export type DeviceReq = {
  pin: string;
  room: string;
  locationId: string;
  name: string;
};
export const addDevice = async (deviceReq: DeviceReq) => {
  const response = await fetch(Endpoint + `/devices`, {
    method: "post",
    body: JSON.stringify({ ...deviceReq, locationId: LocationId }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return (await response.json()) as Device[];
};
