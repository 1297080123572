import { Button } from "antd";
import React from "react";
import "./Embed.scss";
import { useLocalStorage } from "react-use";
import { Identity } from "../guest/api/fetchIdentity";
import { LSRegistrationKey } from "../constants";

const ButtonContent = () => {
  return (
    <svg width="139" height="24" viewBox="0 0 139 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M70.8805 7.80227C71.866 7.80227 72.6324 8.09653 73.1799 8.68506C73.741 9.27359 74.0216 10.0811 74.0216 11.1076V18.868H72.1329V11.3745C72.1329 10.608 71.9823 10.0674 71.6812 9.75262C71.3938 9.43783 70.9695 9.28043 70.4083 9.28043C69.8472 9.28043 69.3476 9.44467 68.9096 9.77316C68.4717 10.1016 68.0611 10.567 67.6778 11.1692V18.868H65.7891V3.71678L67.6778 3.51147V9.54732C68.5264 8.38395 69.594 7.80227 70.8805 7.80227Z"
        fill="white"
      />
      <path
        d="M63.8321 18.3752C63.1204 18.8679 62.3128 19.1143 61.4095 19.1143C60.4925 19.1143 59.7739 18.8542 59.2538 18.3341C58.7474 17.8003 58.4942 17.0339 58.4942 16.0348V9.50619H56.6055V8.04855H58.4942V5.60547L60.383 5.37964V8.04855H62.9493L62.744 9.50619H60.383V15.9526C60.383 16.5138 60.4788 16.9244 60.6704 17.1844C60.8757 17.4308 61.2111 17.554 61.6764 17.554C62.1007 17.554 62.5797 17.4103 63.1135 17.1229L63.8321 18.3752Z"
        fill="white"
      />
      <path
        d="M54.4659 8.04861V18.868H52.5771V8.04861H54.4659ZM53.5009 2.85449C53.8979 2.85449 54.2195 2.97767 54.4659 3.22403C54.7122 3.4704 54.8354 3.77835 54.8354 4.14789C54.8354 4.51743 54.7122 4.82538 54.4659 5.07174C54.2195 5.30442 53.8979 5.42075 53.5009 5.42075C53.1177 5.42075 52.8029 5.30442 52.5566 5.07174C52.3102 4.82538 52.187 4.51743 52.187 4.14789C52.187 3.77835 52.3102 3.4704 52.5566 3.22403C52.8029 2.97767 53.1177 2.85449 53.5009 2.85449Z"
        fill="white"
      />
      <path
        d="M50.2202 8.04858L47.7566 18.8679H45.1903L43.3015 9.75258L41.3512 18.8679H38.8465L36.3213 8.04858H38.2101L40.1604 17.554L42.2955 8.04858H44.4101L46.4426 17.554L48.4135 8.04858H50.2202Z"
        fill="white"
      />
      <path
        d="M26.655 18.9295C26.2307 20.1887 25.6354 21.181 24.8689 21.9064C24.1161 22.6455 23.0349 23.0903 21.6252 23.2409L21.4199 21.7627C22.1316 21.6395 22.6996 21.4616 23.1239 21.2289C23.5481 20.9962 23.8835 20.6951 24.1298 20.3256C24.3899 19.9697 24.6294 19.4838 24.8484 18.8679H24.212L20.5781 8.04858H22.5901L25.4848 17.4924L28.318 8.04858H30.2683L26.655 18.9295Z"
        fill="white"
      />
      <path
        d="M18.7449 16.3428C18.7449 16.7807 18.8201 17.1092 18.9707 17.3282C19.1213 17.5335 19.3471 17.6909 19.6482 17.8004L19.2171 19.1143C18.6559 19.0459 18.2042 18.8885 17.8621 18.6421C17.5199 18.3958 17.2667 18.0125 17.1025 17.4925C16.3771 18.5737 15.3027 19.1143 13.8792 19.1143C12.8117 19.1143 11.9699 18.8132 11.354 18.211C10.7381 17.6088 10.4302 16.8218 10.4302 15.85C10.4302 14.7004 10.8408 13.8176 11.662 13.2017C12.4969 12.5858 13.6739 12.2778 15.1932 12.2778H16.8561V11.4771C16.8561 10.7107 16.6713 10.1632 16.3018 9.83473C15.9322 9.50624 15.3642 9.342 14.5978 9.342C13.804 9.342 12.8322 9.53362 11.6825 9.91685L11.2103 8.54133C12.5516 8.04861 13.7971 7.80225 14.9468 7.80225C16.2197 7.80225 17.1709 8.11704 17.8005 8.74663C18.4301 9.36253 18.7449 10.2453 18.7449 11.395V16.3428ZM14.3104 17.6978C15.3916 17.6978 16.2402 17.1366 16.8561 16.0143V13.5301H15.4395C13.4413 13.5301 12.4421 14.2692 12.4421 15.7474C12.4421 16.3907 12.5995 16.8766 12.9143 17.205C13.2291 17.5335 13.6945 17.6978 14.3104 17.6978Z"
        fill="white"
      />
      <path
        d="M4.46888 4.72278C6.22078 4.72278 7.57577 5.09232 8.53384 5.8314C9.49191 6.5568 9.97095 7.6449 9.97095 9.09569C9.97095 10.6423 9.47138 11.792 8.47225 12.5447C7.47312 13.2975 6.1455 13.6739 4.48941 13.6739H2.70329V18.868H0.75293V4.72278H4.46888ZM4.40729 12.1341C5.54329 12.1341 6.40555 11.9152 6.99408 11.4772C7.5963 11.0392 7.8974 10.2522 7.8974 9.11622C7.8974 8.1034 7.5963 7.37116 6.99408 6.9195C6.40555 6.46784 5.55697 6.24201 4.44835 6.24201H2.70329V12.1341H4.40729Z"
        fill="white"
      />
      <path d="M85.7409 17.5535L81.1001 7.04163H87.9425L89.4751 10.5384L85.7409 17.5535Z" fill="#F9A11B" />
      <path d="M86.4531 18.87H93.2955L102.836 0.868164H95.9937L86.4531 18.87Z" fill="white" />
      <path
        d="M110.801 14.2941H103.764C103.872 15.0711 104.131 15.6107 104.498 15.8698C104.865 16.1288 105.405 16.2799 106.095 16.2799C106.506 16.2799 106.916 16.1935 107.326 16.0209C107.736 15.8482 108.211 15.5892 108.707 15.2438L110.456 17.5966C109.053 18.7406 107.455 19.3233 105.685 19.3233C103.635 19.3233 102.081 18.7621 101.023 17.6181C99.9653 16.4741 99.4473 14.9848 99.4473 13.1069C99.4473 11.9413 99.6631 10.9052 100.116 9.95551C100.57 9.00578 101.217 8.25031 102.081 7.6891C102.944 7.12789 104.002 6.84729 105.232 6.84729C106.376 6.84729 107.369 7.08472 108.232 7.55959C109.096 8.03446 109.743 8.72517 110.218 9.65332C110.693 10.5599 110.93 11.6607 110.93 12.9342C110.93 13.3012 110.909 13.7544 110.844 14.2941H110.801ZM106.657 11.7039C106.657 11.0348 106.527 10.4951 106.333 10.1282C106.139 9.76125 105.793 9.56698 105.275 9.56698C104.8 9.56698 104.433 9.73966 104.196 10.0634C103.959 10.3872 103.807 10.9916 103.721 11.8766H106.635V11.7255L106.657 11.7039Z"
        fill="white"
      />
      <path
        d="M120.255 7.1064L119.586 11.2507C119.197 11.1428 118.83 11.078 118.528 11.078C117.902 11.078 117.427 11.2939 117.104 11.704C116.801 12.1141 116.542 12.7616 116.348 13.6034V18.8702H112.053V7.27907H115.809L116.154 9.45915C116.391 8.70368 116.801 8.07771 117.319 7.62443C117.859 7.17115 118.442 6.93372 119.068 6.93372C119.435 6.93372 119.845 6.99847 120.233 7.12798L120.255 7.1064Z"
        fill="white"
      />
      <path
        d="M124.831 1.5373C125.285 1.99058 125.522 2.5302 125.522 3.17775C125.522 3.82529 125.285 4.3865 124.831 4.8182C124.378 5.27148 123.795 5.48733 123.105 5.48733C122.414 5.48733 121.831 5.27148 121.378 4.8182C120.924 4.3865 120.687 3.82529 120.687 3.17775C120.687 2.5302 120.924 1.99058 121.378 1.5373C121.831 1.08401 122.414 0.868164 123.105 0.868164C123.795 0.868164 124.378 1.08401 124.831 1.5373ZM125.263 7.27887V18.87H120.968V7.27887H125.263Z"
        fill="white"
      />
      <path
        d="M138.3 7.27889V23.8129L134.005 23.3812V18.0066C133.249 18.8916 132.278 19.3232 131.047 19.3232C129.58 19.3232 128.479 18.762 127.745 17.6612C127.011 16.5604 126.644 15.0063 126.644 13.042C126.644 11.8549 126.838 10.7972 127.248 9.84749C127.659 8.89775 128.22 8.18545 128.954 7.64583C129.688 7.12779 130.529 6.86877 131.501 6.86877C132.623 6.86877 133.573 7.27889 134.35 8.09911L134.544 7.30047H138.3V7.27889ZM134.005 15.2005V10.5598C133.789 10.3439 133.594 10.1713 133.4 10.0633C133.206 9.95541 132.99 9.89066 132.753 9.89066C132.235 9.89066 131.824 10.1497 131.522 10.6461C131.22 11.1426 131.069 11.9412 131.069 13.042C131.069 14.3156 131.198 15.1574 131.457 15.5891C131.717 15.9992 132.062 16.215 132.537 16.215C133.141 16.215 133.638 15.8697 134.026 15.2005H134.005Z"
        fill="white"
      />
    </svg>
  );
};

const Embed: React.FC = () => {
  const [identity] = useLocalStorage<Identity>(LSRegistrationKey);

  return (
    <Button
      onClick={() => window.parent.postMessage(identity?.key, "*")}
      style={{ width: "100%", backgroundColor: "#32525B" }}
      type="primary"
      size="large"
    >
      <ButtonContent />
    </Button>
  );
};

export default Embed;
