import React from "react";
import "./DeskView.scss";
import { Avatar, Card, Layout, Menu } from "antd";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import { Outlet } from "react-router-dom";
import { ReactComponent as Logo } from "./veriq.svg";

const contentStyle: React.CSSProperties = {
  padding: 20,
  backgroundColor: "#F3F5FB",
};

const siderStyle: React.CSSProperties = {
  textAlign: "center",
  lineHeight: "120px",
  backgroundColor: "#F3F5FB",
};

const DeskView: React.FC = () => (
  <Layout hasSider style={{ height: "100vh" }}>
    <Sider style={siderStyle} width={250}>
      <div style={{ display: "flex", padding: 25 }}>
        <Logo />
      </div>

      <Menu
        mode="inline"
        style={{ borderRight: 0, paddingLeft: 20, backgroundColor: "#F3F5FB" }}
        defaultSelectedKeys={["guests"]}
      >
        <Menu.Item key="guests">Guests</Menu.Item>
        <Menu.Item key="locations">Locations</Menu.Item>
      </Menu>
      <div style={{ display: "flex", padding: 25, bottom: 0, position: "absolute" }}>
        <Avatar src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" size="large" />
      </div>
    </Sider>
    <Content style={contentStyle}>
      <Card style={{ height: "100%" }}>
        <Outlet />
      </Card>
    </Content>
  </Layout>
);
export default DeskView;
