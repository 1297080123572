import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Desk from "./desk/DeskView";
import Guest from "./guest/Guest";
import { App } from "antd";
import Embed from "./embeddable-button/Embed";
import { Devices } from "./desk/devices/Devices";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <App>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Guest />} />
          <Route path="/guest" element={<Guest />} />
          <Route path="/desk" element={<Desk />}>
            <Route index path="" element={<Devices />} />
          </Route>
          <Route path="/embed" element={<Embed />} />
        </Routes>
      </BrowserRouter>
    </App>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
