import { Card, Flex, Progress, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import "./Guest.scss";
import { useAsyncFn, useInterval, useLocalStorage } from "react-use";
import { Identity, fetchIdentity } from "./api/fetchIdentity";

import { ReactComponent as Bubbles } from "./bubbles.svg";
import { ReactComponent as OceanSide } from "./Oceanside_logo.svg";
import { FancyPin } from "./components/FancyPin";
import { CheckCircleFilled, LoadingOutlined } from "@ant-design/icons";
import { fetchDevice } from "../desk/devices/api/fetchDevice";
import { Device } from "../desk/devices/api/fetchDevices";
import { useAsyncInterval } from "../hooks/useInterval2";
import { LSIdentityKey, LSRegistrationKey } from "../constants";

const RefreshIdentityTimeout = 30;

const Guest: React.FC = () => {
  const { Text, Title } = Typography;

  const [identity, setIdentity, removeIdentity] = useLocalStorage<Identity>(LSIdentityKey);
  const [device, setDevice, removeDevice] = useLocalStorage<Device>(LSRegistrationKey);

  const [progress, setProgress] = useState<number>(0);
  const [state, doFetchIdentity] = useAsyncFn(async () => await fetchIdentity(), []);

  useEffect(() => {
    setIdentity(state.value);
    setProgress(0);
  }, [setIdentity, state.value]);

  useEffect(() => {
    if (device === undefined) {
      if (progress === RefreshIdentityTimeout) {
        doFetchIdentity();
      }
    }
  }, [device, doFetchIdentity, progress]);

  useEffect(() => {
    // if no device is registered on device, or the device's expiry time is expired remove the device and identities and do the fetch loop
    if (!device || new Date(device.expiresAt).getTime() < Date.now()) {
      removeDevice();
      removeIdentity();
      doFetchIdentity();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(() => setProgress((prev) => prev + 1), 1000);

  useAsyncInterval(async () => {
    if (identity && device === undefined) {
      const res = await fetchDevice(identity?.key);
      setDevice(res);
    }
  }, 2500);

  return (
    <Flex vertical style={{ backgroundColor: "#22172B", height: "100vh", alignItems: "center" }}>
      <Bubbles style={{ transform: "scale(.8)", position: "absolute", top: -100 }} />
      <OceanSide style={{ transform: "scale(.8)", paddingTop: 100, paddingBottom: 100 }} />

      {!device ? (
        <>
          <Title level={1} style={{ textAlign: "center", padding: 0, margin: 0, color: "white" }}>
            Device Setup
          </Title>
          <Text style={{ textAlign: "center", color: "grey", paddingBottom: 25, paddingTop: 20 }}>
            Provide this code to front desk <br />
            agent to register.
          </Text>
          <Flex gap={16} align="center">
            {!state.loading && identity?.pin ? <FancyPin pin={identity.pin} /> : <FancyPin pin="    " />}

            {state.loading ? (
              <Spin indicator={<LoadingOutlined spin style={{ fontSize: 30, color: "#77ACA2", strokeWidth: 50 }} />} />
            ) : (
              <Progress
                type="circle"
                percent={(progress / RefreshIdentityTimeout) * 100}
                style={{ fontSize: 0 }}
                showInfo={false}
                trailColor="#555555"
                strokeColor="#77ACA2"
                strokeLinecap="butt"
                size={35}
                strokeWidth={15}
              />
            )}
          </Flex>
        </>
      ) : (
        <Flex vertical gap={30} align="center">
          <CheckCircleFilled style={{ color: "#77ACA2", fontSize: 50 }} />
          <Title level={1} style={{ textAlign: "center", padding: 0, margin: 0, color: "white" }}>
            You're all set
          </Title>
          <Card
            title={"Room " + device.room}
            color="white"
            style={{ backgroundColor: "#2F203C", color: "white", borderColor: "#55555" }}
            headStyle={{ color: "white ", borderColor: "#55555" }}
          >
            <Flex vertical gap={3}>
              {device.name && <Text style={{ color: "white" }}>Name: {device.name}</Text>}
              <Text style={{ color: "white" }}>
                Created: {new Date(device.createdAt).toLocaleDateString()}&nbsp;
                {new Date(device.createdAt).toLocaleTimeString()}
              </Text>
              <Text style={{ color: "white" }}>
                Expires: {new Date(device.expiresAt).toLocaleDateString()}&nbsp;
                {new Date(device.expiresAt).toLocaleTimeString()}
              </Text>
            </Flex>
          </Card>
        </Flex>
      )}
    </Flex>
  );
};

export default Guest;
